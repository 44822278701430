jQuery.validator.addMethod("passwordonly", function (value, element) {
    var regx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9 _.-@#!^&$~]+$/;
    //var patternPwd = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z][^_$@.]{8,}/;;
    return this.optional(element) || regx.test(value);
}, "Please enter at least 8 characters, 1 digit, 1 uppercase character and 1 lowercase character");

jQuery.validator.addMethod("postalcodeonly", function (value, element) {
    return this.optional(element) || /^(\d{5}([\-]\d{4})?)$/.test(value) || /^[0-9]{4}$/.test(value) || /^[0-9]{5}[\-]?[0-9]{3}$/.test(value) || /^[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]$/.test(value) || /^[0-9]{3,4}$/.test(value) || /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/.test(value) || /^[0-9]{5}$/.test(value) || /^\d{3}-\d{4}$/.test(value) || /^(L\s*(-|—|–))\s*?[\d]{4}$/.test(value) || /^[0-9]{2}\-[0-9]{3}$/.test(value) || /^((0[1-9]|5[0-2])|[1-4][0-9])[0-9]{3}$/.test(value) || /^\d{3}\s?\d{2}$/.test(value) || /^[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}$/.test(value)
}, "Please enter a valid postal code.");

jQuery.validator.addMethod("phoneonly", function (value, element) {
    var regx = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
    return this.optional(element) || regx.test(value);
}, "Please enter a valid 10-digit US phone number.");

jQuery.validator.addMethod("alphanumericonly", function (value, element) {
    var regx = /^[a-zA-Z0-9]*$/;
    return this.optional(element) || regx.test(value);
}, "Please enter an alphanumeric value.");

jQuery.validator.addMethod("daypart_abbrv_exists", function (value, element) {
    if($('#day_part_current_abbreviation').length !== 0){
        if($('#day_part_current_abbreviation').val() === value){
            return true;
        }
    }
    var result = true;
    $.ajax({
        type: "GET",
        encode: true,
        url: "/dayparts/exists",
        data: {'val': value},
        async: false,
        success: function (data) {
            if (data === true) {
                result = false;
            } else {
                result = true;
            }
        },
        error: function (xhr) {
            console.log(xhr);
        }
    });

    return result;

}, "Abbreviation already exists.");

jQuery.validator.addMethod("daypart_progname_exists", function (value, element) {
    if($('#day_part_current_program_name').length !== 0){
        if($('#day_part_current_program_name').val() === value){
            return true;
        }
    }
    var result = true;
    $.ajax({
        type: "GET",
        encode: true,
        url: "/dayparts/existsprogname",
        data: {'val': value},
        async: false,
        success: function (data) {
            if (data === true) {
                result = false;
            } else {
                result = true;
            }
        },
        error: function (xhr) {
            console.log(xhr);
        }
    });

    return result;

}, "Program Name already exists.");

jQuery.validator.addMethod("dows", function (value, element) {
    if (value == "-------") {
        return false;
    } else {
        return true;
    }
}, "You must define at least one day of week.");

jQuery.validator.addMethod("notEqualTo", function (value, element, param) {
    other_val = $(param).val();
    return this.optional(element) || value != other_val;
}, "Please specify a different (non-default) value");

jQuery.validator.addMethod("user_email_exists", function (value, element) {
    if($('#user_current_email').length !== 0){
        if($('#user_current_email').val() === value){
            return true;
        }
    }
    var result = true;
    $.ajax({
        type: "GET",
        encode: true,
        url: "/users/exists",
        data: {'val': value},
        async: false,
        success: function (data) {
            if (data === true) {
                result = false;
            } else {
                result = true;
            }
        },
        error: function (xhr) {
            console.log(xhr);
        }
    });

    return result;

}, "A user with this email account already exists.");

jQuery.validator.addMethod("vendor_channel", function (value, element) {
    var regx = /^[0-9]{1,3}[.]?[0-9]?$/;
    return this.optional(element) || regx.test(value);
}, "Please enter a channel number between 0 and 999.9");


var form_validator = function (selector, rules, messages) {
    var form = $(selector);

    $.validator.addMethod('checkurl', function(value, element) {
        var url = $.validator.methods.url.bind(this);
        return url(value, element) || url('http://' + value, element);
    }, 'Please enter a valid URL');

    // connect it to a css class
    $.validator.addClassRules({
        checkurl : { checkurl : true }
    });

    // connect it to a css class
    jQuery.validator.addClassRules({
        checkurl : { checkurl : true }
    });

    var $validator = form.validate({
        rules: rules,
        messages: messages,
        errorClass: "with-errors",
        validClass: "",
        errorElement: "div",
        ignore: ".ignore",
        onkeyup: function (element, e) {
            /*if(e.keyCode == 9) {
                var $inputs = $(selector + " :input");
                var ind = -1;
                $inputs.each(function(index, value) {
                    if(this.id === element.id)
                        ind = index;
                    else{
                        if(ind !== -1){
                            $(this).focus();
                            ind = -1;
                            return false;
                        }
                    }
                    //values[this.name] = $(this).val();
                });
            }
            return false;*/
        },
        onkeydown: function (element) {
            console.log("onkeydown");
            return false;
        },
        onfocusout: function (element) {
            console.log("onfocusout");
            this.element(element);
        },
        errorPlacement: function (error, element) {
            $(element).parent().append(error);
        }
    });

    // Special case for select2 error styling. There was no validator event to properly handle this.
    form.find('.wizard-next, .wizard-finish, button[type="submit"]').on('click', function () {
        //if(!$(this).hasClass("smart-finish")) {
            if (form.find('select').length) {
                form.find('select').each(function (index, value) {
                    select = form.find('select[name="' + $(this).attr('name') + '"]');
                    if (select.length) {
                        if (!select.hasClass('ignore')) {
                            if (!select.valid()) {
                                select.closest('div.select2-error').addClass('select2-with-errors');
                            } else {
                                select.closest('div.select2-error').removeClass('select2-with-errors');
                            }
                        }
                    }
                });
            }
        //}
    });

    return $validator;
};